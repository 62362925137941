import React from "react";
import check from '../../../assets/images/check.svg'
import './Clients.css'
import { useNavigate } from 'react-router-dom'
import { getSuggestedQuery } from "@testing-library/react";

export default function Clients() {

    const navigate = useNavigate()

    return (
        <div className="clients">
            <h1 className="impact-heading">Designed to Suit <span className="pink">All Clients</span></h1>
            <p>Whatever your needs maybe, we got you covered!</p>
            <div className="client-card-container">
                <div className="price-card">
                    <div className="price-card-light">
                        <div className="price-header">Basic</div>
                        <div className="price"><sup>$</sup>0</div>
                    </div>
                    <div className="price-card-dark">
                        <div className="price-list-item"><img src={check} className="check" />Mint 100 NFTs</div>
                        <div className="price-list-item"><img src={check} className="check" />Access to NFT Metadata</div>
                        <div className="price-list-item"><img src={check} className="check" />Priority Support</div>
                        <div className="price-list-item"><img src={check} className="check" />Access to Visualization Tools</div>
                        <button className="price-getstarted" onClick={(e) => {
                            e.preventDefault()
                            navigate('/login')
                        }}>Get Started</button>
                    </div>
                </div>
                <div className="price-card big-card">
                    <div className="price-card-light">
                        <div className="price-header">Pay as you Go</div>
                        <div className="price"><sup>$</sup>1.00<sup>/1000 API Calls</sup></div>
                    </div>
                    <div className="price-card-dark">
                        <div className="price-list-item"><img src={check} className="check" />Mint unlimited NFTs</div>
                        <div className="price-list-item"><img src={check} className="check" />Unlimited Scalability</div>
                        <div className="price-list-item"><img src={check} className="check" />Priority Support</div>
                        <div className="price-list-item"><img src={check} className="check" />Full access to visualization</div>
                        <div className="price-list-item"><img src={check} className="check" />Feature Request Priority</div>
                        <button className="price-getstarted" style={{ background: "grey" }}>Coming soon</button>
                    </div>
                </div>
                <div className="price-card">
                    <div className="price-card-light">
                        <div className="price-header">Enterprise</div>
                        <div className="price">Custom</div>
                    </div>
                    <div className="price-card-dark">
                        <div className="price-list-item"><img src={check} className="check" />Mint Unlimited NFTs</div>
                        <div className="price-list-item"><img src={check} className="check" />Dedicated Infrastructure</div>
                        <div className="price-list-item"><img src={check} className="check" />No File Size Restrictions</div>
                        <div className="price-list-item"><img src={check} className="check" />Dedicated Support Engineer</div>
                        <button className="price-getstarted" onClick={(e) => {
                            e.preventDefault();
                            window.open('https://docs.google.com/forms/d/e/1FAIpQLSc5sSyUJXs90iPT8fg2mZ7R8KAc2-jzsi6kmS6uNYOskavAng/viewform?usp=sf_link', "_blank");
                        }}>Contact Us!</button>
                    </div>
                </div>
            </div>
        </div >
    );

}