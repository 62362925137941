import "./Projects.css";
import ProjectsCard from "./ProjectsCard/ProjectsCard";
import {useAuth, db} from "../../../Auth";
import React, {useContext, useState, useEffect, useRef} from "react";
import axios from "axios";
import loading_icon from '../../../assets/images/loading.gif'

function ProjectRenderer({projects_}) {
    const {currentUser} = useAuth();
    console.log(projects_);
    return projects_.map((project) => {
        return (
        <ProjectsCard
            name={project.projectName}
            users={currentUser.user.username}
            key={project.id}
            id ={project.id}
        />
        );
    });
}

function Projects(props) {
  const {currentUser} = useAuth();
  const [isAdding, setIsAdding] = useState(false);
  const [isAddingProject, setIsAddingProject] = useState(false);
  const [error, setError] = useState()

  const projectNameRef = useRef();

  function addProject(e) {
    setIsAdding(true);
  }

  async function addProjectButton(e) {
    e.preventDefault();
    if (projectNameRef.current.value.length < 4) {
      setError("Project names can't be shorter than 4 characters!")
    } else {
      setError("")
      setIsAddingProject(true);
      fetch(
        "https://us-central1-tokenstack-dev.cloudfunctions.net/app/project/create",
        {
          method: "post",
          body: JSON.stringify({
            projectName: projectNameRef.current.value,
            userId: currentUser.user.id,
          }),
          headers: {"content-type": "application/json"},
        }
      )
        .then((response) => setIsAddingProject(false))
        .catch((error) => {
          if (error.response) {
            console.log(error.response.headers);
          }
        })
        .finally(() => {
            setIsAdding(false)
            window.location.reload(false)
          });
    }
  }

  function closeAdd(e) {
    e.preventDefault();
    setError("")
    setIsAdding(false);
  }

  return (
    <div className="Projects">
      <div className={isAdding ? "ProjectsContainer blurred" : "ProjectsContainer"}>
        <ProjectRenderer projects_={currentUser.projects} />
        <div className="AddProject">
          <button className="AddProjectButton" onClick={addProject}>
            +
          </button>
        </div>
      </div>
      {isAdding? <div className="popupContainer"><button className="popupcover" onClick={closeAdd}></button><form className="AddForm">
        <div className="popupTitle"><span className="gradient-text">Create a project:</span></div>
        <input
          type="text"
          className="input-projectname"
          placeholder="Project name"
          ref={projectNameRef}
          required
          autoFocus
        ></input>
        {error === "" ? <></> : <div className="ProjectAddError">{error}</div>}
        <button
          type="submit"
          className="AddProjectFormButton"
          onClick={addProjectButton}
        >
          +
        </button>
        {isAddingProject ? <img className="loadingSignProjectAdd" src={loading_icon}/>: <div></div>}
      </form></div>: <></>}
    </div>
  );
}

export default Projects;