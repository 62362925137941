import './App.css';
import HomePage from './components/Home/HomePage'
import Login from './components/Login/LoginPage'
import SignUp from './components/Login/SignUp/SignUp'
import Dashboard from './components/Dashboard/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from './Auth'
import { useEffect } from 'react';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Project from './components/Dashboard/Projects/Project/Project'
import UserPage from './components/Dashboard/UserPage/UserPage';

function App() {
  return (
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
            <Route path ="/dashboard/:projectID" element={<Project></Project>}></Route>
            <Route path="/profile" element={<PrivateRoute><UserPage></UserPage></PrivateRoute>}/>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
  );
}

export default App;
