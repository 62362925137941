import Projects from './Projects/Projects'
import './Dashboard.css'
import { useState, useEffect } from 'react';
import { useAuth, db } from '../../Auth'
import { useNavigate } from 'react-router-dom'
import { doc } from 'firebase/firestore'
import DashboardHeader from './DashboardHeader/DashboardHeader'
import VerifyEmail from './VerifyEmail/VerifyEmail';
import Footer from '../UI/Footer/Footer'
import { animateElement } from '../Animations/FadeIn';

function Dashboard() {
    const [tab, setTab] = useState(true);
    const [error, setError] = useState("")
    const { currentUser, logout, loading } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        const Projects = document.querySelector(".Projects");
        animateElement([Projects]);
    
        const listener = () => {
          animateElement([Projects]);
        };
        window.addEventListener("scroll", listener);
        return () => window.removeEventListener("scroll", listener);
    }, []);

    if (currentUser.user == null || loading == true) {
        console.log('ok')
        return(<div>Loading</div>)
    } else if(currentUser.emailVerified == false) {
        return <VerifyEmail/>
    } else {
        console.log(currentUser)
        return (
            <div className="Dashboard">
                <DashboardHeader></DashboardHeader>
                <Projects projects={currentUser.projects}/>
                <Footer></Footer>
            </div>
        );
    }
}

export default Dashboard;