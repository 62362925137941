import logo from '../../assets/images/tokenstack_logo_icon.svg'
import './LoginPage.css'
import React, { useState, useRef, useEffect} from 'react';  
import LoginForm from './LoginForm/LoginForm';
import SignUp from './SignUp/SignUp';
import Footer from '../UI/Footer/Footer'
import { animateElement } from '../Animations/FadeIn';
import { useAuth } from '../../Auth'
import { useNavigate } from 'react-router-dom'

function LoginPage() {
    const [isLogin, setIsLogin] = useState(true)
    const { currentUser } = useAuth()

    const navigate = useNavigate()
    
    useEffect(() => {

        const logo_anim = document.querySelector(".login-header-logo");
        const login_form = document.querySelector(".login-right");
        animateElement([logo_anim, login_form]);
    
        const listener = () => {
          animateElement([login_form, logo_anim]);
        }; 
        window.addEventListener("scroll", listener);
        return () => window.removeEventListener("scroll", listener);
    }, [])

    return (
        <div className="Login">
            <div className="login-page-container">
                <div className="login-left">
                    <a href='/'><img className="login-header-logo" src={logo}/></a>
                </div>
                <div className='login-right'>
                    <div className='login-switch'>
                        <div className={isLogin? 'orange-bg' : 'orange-bg right'}></div>
                        <button className='login-switch-login' onClick={(e) => {
                            e.preventDefault();
                            setIsLogin(!isLogin);
                        }}>Login</button>
                        <button className='login-switch-signup' onClick={(e) => {
                            e.preventDefault();
                            setIsLogin(!isLogin);
                        }}>Signup</button>
                    </div>
                    {isLogin ? <LoginForm/> : <SignUp/>}
                </div>
            </div>
            <Footer></Footer>
        </div>

    );
}

export default LoginPage;