import "../LoginPage.css";
import google_button from "../../../assets/images/google.png";
import facebook from '../../../assets/images/facebook.png'
import twitter from '../../../assets/images/twitter.png'
import { useAuth } from "../../../Auth";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const usernameRef = useRef();
  const { signup, loginWithGoogle, loginWithTwitter, loginWithFacebook } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  async function handleSignUp(event) {
    event.preventDefault();
    const username = usernameRef.current.value.toString();
    if (username.trim() < 4) {
      setError("Username can't be shorter than 4 characters!");
    } else {
      try {
        setError("");
        const res = await signup(
          emailRef.current.value,
          passwordRef.current.value,
          username.trim()
        ).then((response) => {
          if (response != null && response.hasOwnProperty("error")) {
            setError(response.error);
            console.log(error);
            console.log(response);
          } else {
            console.log("bruh");
            navigate("/dashboard");
          }
        })

      } catch (err) {
        console.log(err);
        switch (err.code) {
          case "auth/email-already-in-use":
          case "auth/invalid-email":
            setError(err.message);
            break;
          case "auth/weak-password":
            setError(err.message);
            break;
        }
      }
    }
  }

  async function handleGoogleLogin(event) {
    event.preventDefault();
    try {
      setError("");
      await loginWithGoogle();
      navigate("/dashboard");
    } catch (error) {
      setError(error)
    }
  }

  async function handleTwitterLogin(event) {
    event.preventDefault();
    try {
      setError("");
      await loginWithTwitter();
      navigate("/dashboard");
    } catch (error) {
      setError(error)
    }
  }

  async function handleFacebookLogin(event) {
    event.preventDefault();
    try {
      setError("");
      await loginWithFacebook();
      navigate("/dashboard");
    } catch (error) {
      setError(error)
    }
  }

  return (
    <div className="form-container slideFromLeftForm">
      <form
        className="login-form"
        onSubmit={handleSignUp}
      >
        <input
          type="email"
          className="input-login"
          placeholder="Email"
          ref={emailRef}
          required
          autoFocus
        ></input>
        <input
          type="password"
          className="input-login"
          placeholder="Password"
          ref={passwordRef}
          required
        ></input>
        <input
          type="username"
          className="input-login"
          placeholder="Username"
          ref={usernameRef}
          required
        ></input>
        <button type="submit" className="submit-button-login">
          Sign up
        </button>
        {error === "" ? <></> : <div className="form-error">{error}</div>}
        <div className="social-login-container">
          <button type="button" onClick={handleGoogleLogin} className="google-button">
            <img src={google_button} className="google_button_img"></img>
          </button>
          {/* <button type="button" onClick={handleTwitterLogin} className="google-button">
            <img src={twitter} className="google_button_img"></img>
          </button> */}
        </div>

      </form>
    </div>
  );
}

export default SignUp;