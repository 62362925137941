import './NFTLibrary.css'
import expandArrow from '../../../../../assets/images/expandArrow.png'

export default function NFTLibrary(props) {

    function fetchNFTs() {
        var NFTs = props.NFTs
        if (NFTs.length == 0) {
            return(<div className='NoNFTS'><span className='gradient-text'>No NFTs Minted Yet!</span></div>)
        }
        return NFTs.map((NFT) => {
            return (
                <div className='NFTContainer' key={NFT.transactionHash}>
                    <a href={NFT.file}><div>{NFT.File}</div></a>
                    <img src={NFT.file} className="NFT"></img>
                </div>
            )
        })
    }

    return (
        <div className='NFTLibrary'>
            <div className='NFTLibraryHeader'>
                <div className='NFTLibraryHeading'>Your NFT Library</div>
            </div>
            <div className='NFTContainerGrid'>
                {fetchNFTs()}
            </div>
        </div>
    )
}