import React from 'react';
import { FaInstagram, FaFacebookF, FaTwitter, FaLinkedinIn, FaEnvelope, FaGithub } from "react-icons/fa";

import './Footer.css';

const Footer = (props) => {

    return (
        <div className="Footer">
            <div className="FooterContent">
                <div className="FooterCol">
                    <p style={{ marginBottom: "25px" }} className="FooterColHeader">Pages</p>
                    <a href="/"><p>Home</p></a>
                    <a href="/dashboard"><p>Dashboard</p></a>
                    <a href="/login"><p>Login</p></a>
                    <a href="/profile"><p>Account</p></a>
                </div>
                <div className="FooterCol">
                    <p style={{ marginBottom: "25px" }} className="FooterColHeader">Company</p>
                    <a href="#"><p>Terms Of Service</p></a>
                    <a href="#"><p>Privacy Policy</p></a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5sSyUJXs90iPT8fg2mZ7R8KAc2-jzsi6kmS6uNYOskavAng/viewform?usp=sf_link"><p>Contact Us</p></a>
                    <a href="https://twitter.com/usetokenstack"><p>Twitter</p></a>
                </div>
                <div className="FooterCol">
                    <p style={{ marginBottom: "25px" }} className="FooterColHeader">Get Started</p>
                    <a href="/register"><p>Login</p></a>
                    <a href="/register"><p>Register</p></a>
                    <a href="/profile"><p>Account</p></a>
                    <a href="/dashboard"><p>Create</p></a>
                </div>
                <div className="FooterCol">
                    <p style={{ marginBottom: "25px" }} className="FooterColHeader">Contact</p>
                    <p>Headquarters - Atlanta, Georgia</p>
                    <a href="mailto:contact@tokenstack.dev"><p>Email - contact@tokenstack.dev</p></a>
                </div>
            </div>
            <div className="FooterSubContent">
                <div className="FooterSocials">
                    <a href="https://twitter.com/usetokenstack" ><FaTwitter /></a>
                    {/* <a href="https://www.linkedin.com/company/swiftdeckai/" ><FaLinkedinIn /></a> */}
                    <a href="mailto:contact@tokenstack.dev" ><FaEnvelope /></a>
                    <a href="https://github.com/tokenstack"><FaGithub /></a>
                </div>
                <p>&copy; tokenstack</p>
            </div>
        </div>
    );
}

export default Footer;