import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import {createState} from "statedrive/react"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

firebase.initializeApp(firebaseConfig)

const provider_google = new firebase.auth.GoogleAuthProvider();
const provider_twitter = new firebase.auth.TwitterAuthProvider();
const provider_facebook = new firebase.auth.FacebookAuthProvider();

provider_google.setCustomParameters({ prompt: 'select_account' });
provider_twitter.setCustomParameters({ prompt: 'select_account' });
provider_facebook.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider_google);
export const signInWithTwitter = () => auth.signInWithPopup(provider_twitter);
export const signInWithFacebook = () => auth.signInWithPopup(provider_facebook);

export const auth = firebase.auth();

export default firebase