import React from "react";
import logo from '../../../assets/images/tokenstack_logo_text_lightmode.png'
import './Header.css'

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="header">
                <img className="header-logo" src={logo} />
                <div className="login-div">
                    <a className="login" href="/dashboard">Dashboard</a>
                    <a href="/login"><p className="getstarted">Get Started</p></a>
                </div>
            </div>
        );
    }
}

export default Header;