import './NFTTokenInfoTable.css'

export default function NFTTokenInfoTable(props) {
    function fetchNFTsData() {
        var NFTs = props.NFTs
        if (NFTs.length == 0) {
            return(<div></div>)
        } else {
            
        }
        return NFTs.map((NFT) => {
            return (
                <div className='NFTTokenRow' key={NFT.transactionHash}>
                    <div className='Network cell'>
                        <div className='cell-content'>{NFT.network}</div>
                    </div>
                    <div className='GasFee cell'>
                        <div className='cell-content'>{NFT.gasFee}</div>
                    </div>
                    <div className='NFTOwner cell'>
                        <div className='cell-content'>{NFT.nftOwner}</div>
                    </div>
                    <div className='NFTType cell'>
                        <div className='cell-content'>{NFT.nftType}</div>
                    </div>
                </div>
            );
        });
    }

    return (
        <div className='NFTTokens'>
            <div className='NFTTokensHeader'>
                <div className='NFTTokensHeading'>NFT Tokens</div>
            </div>
            <div className={props.NFTs.length == 0 ? 'NFTTokensTable empty' : 'NFTTokensTable'}>
                    {props.NFTs.length == 0 ? <div className='NoNFTS'><span className='gradient-text'>No NFTs Minted Yet!</span></div> : <div className='NFTTokenTableHeader'>
                        <div className='Network'>Network</div>
                        <div className='GasFee'>Gas Fee</div>
                        <div className='NFTOwner'>NFT Owner</div>
                        <div className='NFTType'>NFT Type</div>
                    </div>}
                   
                    
                    {fetchNFTsData()}
            </div>
        </div>
    )
}