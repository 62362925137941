import './UserPage.css'
import { useEffect, useState } from 'react'
import { useAuth, db } from '../../../Auth'
import DashboardHeader from '../DashboardHeader/DashboardHeader'
import VerifyEmail from '../VerifyEmail/VerifyEmail'
import Footer from '../../UI/Footer/Footer'
import { animateElement } from '../../Animations/FadeIn'

export default function UserPage() {
    const { currentUser, logout, loading } = useAuth()
    const [apikey, setApikey] = useState(null)
    const [user, setUser] = useState(null)

    useEffect(() => {
        setUser(currentUser)
        const userpage = document.querySelector(".UserPageContainer");
        animateElement([userpage]);
    
        const listener = () => {
          animateElement([userpage]);
        };
        window.addEventListener("scroll", listener);
        return () => window.removeEventListener("scroll", listener);
    }, [])

    useEffect(async () => {
        try {
            console.log(user)
            const userSnapshot = await (await db.collection('api_keys').where("owner", "==", currentUser.user.id).get()).docs
            const apiSnapshot = userSnapshot[0].data()
            setApikey(apiSnapshot.apiKey)
        } catch(e) {
            console.log(e)
            setApikey('')
        }
    }, [user])

    if (currentUser.user == null || loading == true) {
        console.log('ok')
        return(<div>Loading</div>)
    } else if(currentUser.emailVerified == false) {
        return <VerifyEmail/>
    } else {
        return (
            <div className="UserPage">
                <DashboardHeader></DashboardHeader>
                <div className='UserPageContainer'>
                    <div className='UserPageHeading'>Profile</div>
                    <div className='ProfileInfoContainer'>
                        <div className='ProfileInfoLabel'>USERNAME</div>
                        <div className='ProfileInfo'>{currentUser.user.username}</div>
                    </div>
                    <div className='ProfileInfoContainer'>
                        <div className='ProfileInfoLabel'>TIER</div>
                        <div className='ProfileInfo'>{currentUser.user.tier}</div>
                    </div>
                    <div className='ProfileInfoContainer'>
                        <div className='ProfileInfoLabel'>EMAIL</div>
                        <div className='ProfileInfo'>{currentUser.user.email}</div>
                    </div>
                    <div className='ProfileInfoContainer'>
                        <div className='ProfileInfoLabel'>API KEY</div>
                        <div className='ProfileInfo'><span className='gradient-text'>{apikey}</span></div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}