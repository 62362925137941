import './ProjectsCard.css'
import UserContainer from './UserContainer/UserContainer';
import expandArrow from '../../../../assets/images/expandArrow.png'
import { useNavigate } from 'react-router-dom';
import orangecircle from '../../../../assets/images/orangecircle.png'


export default function ProjectsCard(props) {

    const navigate = useNavigate()

    function renderUsers() {
        return (
            <UserContainer username={props.users} key={props.users}/>
        );
    }

    function expandProject(e) {
        e.preventDefault()
        navigate('/dashboard/' + props.id)
    }

    return (
        <div className='ProjectsCard'>
            <div className='ProjectsCardHeadingContainer'>
                <div className='ProjectsCardHeadingHeadingContainer'>
                    <div className='ProjectsCardHeading'>{props.name}</div>
                </div>
                <div className='ExpandContainer' onClick={expandProject}>
                    <div className='Expand'>Expand</div>
                    <img src={expandArrow}></img>
                </div>
            </div>
            <div className='ProjectsCardTableHeader'>Users</div>
            <div className='UsersContainer'>
                {renderUsers()}
            </div>
        </div>
    );
}