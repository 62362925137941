import React from "react";
import seamless from '../../../assets/images/seamless.png'
import secure from '../../../assets/images/secure.png'
import visualize from '../../../assets/images/visualize.png'
import './Impact.css'
import blur1 from '../../../assets/images/blur1.png'

function Impact() {

    return (
        <div className="impact">
            <img src={blur1} className="blur1"/>
            <img src={blur1} className="blur2"/>
            <img src={blur1} className="blur3"/>
            <div className="impact-content-container">
                <h1 className="impact-heading">Build NFTs With <span className="orange">Impact</span></h1>
                <p className="impact-body">Experience the future of NFTs through the use of our NFT API</p>
                <div className="impact-grid">
                    <div className="impact-card">
                        <div className="card-header">TOKENSTACK NFT API</div>
                        <div className="card-heading">Seamless Integration</div>
                        <p className="card-body">Our API integrates seamlessly with your product, with a simple and easy to follow documentation. We're also available to support you and your business for any issues that might arise.</p>
                        <img src={seamless} className="card-img" />
                    </div>
                    <div className="impact-card">
                        <div className="card-header">TOKENSTACK NFT API</div>
                        <div className="card-heading">Clean and Simple Visualizations</div>
                        <p className="card-body">Our NFT Developer Tools provide an in-house suite of visualization tools which allow you to view information about each NFT minted and preview the data stored within each NFT.</p>
                        <img src={visualize} className="card-img" />
                    </div>
                    <div className="impact-card">
                        <div className="card-header">TOKENSTACK NFT API</div>
                        <div className="card-heading">Building NFTs Securely</div>
                        <p className="card-body">We believe every person deserves to know what happens with their Crypto. That's why we have completely open-sourced our smart contracts to deliver the transparency every user deserves.</p>
                        <img src={secure} className="card-img" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Impact;