import React, {useContext, useState, useEffect, useRef} from "react";
import {auth, signInWithGoogle, signInWithTwitter, signInWithFacebook} from "./components/Firebase/firebase";
import firebase from "./components/Firebase/firebase";
import axios from "axios";
import LoadingPage from "./components/Loading/LoadingPage";

export const db = firebase.firestore();

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState();

  async function signup(email, password, username) {
    setUsername(username);
    console.log(username);
    try {
      return await auth.createUserWithEmailAndPassword(email, password).then(async (response) => await response.user.sendEmailVerification({url: "https://tokenstack.dev/dashboard"}));
    } catch {
      return {error: "Failed to create user"};
    }
  }

  async function addUserToDatabase(email, authID, username) {
    return new Promise(function (resolve, reject) {
      axios({
        method: "post",
        url: "https://us-central1-tokenstack-dev.cloudfunctions.net/app/user/create",
        data: {
          email: email,
          tier: "basic",
          username: username,
          authId: authID,
        },
      }).then(
        (response) => {
          var result = response.data;
          resolve(result);
          console.log(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function loginWithGoogle() {
    return signInWithGoogle();
  }

  function loginWithFacebook() {
    return signInWithFacebook();
  }

  function loginWithTwitter() {
    return signInWithTwitter();
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  async function fetchProjects(projects) {
    const newProjects = await Promise.all(
      projects.map(async (project) => {
        const projectSnapshot = await db
          .collection("projects")
          .doc(project)
          .get();
        const data = projectSnapshot.data();
        return data;
      })
    );
    return newProjects;
  }

  async function fetchUser(user) {
    const userSnapshot = await db
      .collection("users")
      .where("authId", "==", user.uid)
      .get();
    const docSnapshot = userSnapshot.docs;
    const ok = docSnapshot[0].data();
    const newProjects = await fetchProjects(ok.projects);
    const Provider = user.providerData
    setCurrentUser({user: ok, projects: newProjects, emailVerified: user.emailVerified || Provider[0].providerId === "twitter.com" || Provider[0].providerId === "facebook.com"});
  }

  const prevState = useRef({});
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (prevState.current == user) {
        return console.log("skipping");
      }
      prevState.current = user;
      setLoading(true);
      console.log(user);
      if (user != null) {
        try {
          await fetchUser(user);
          setLoading(false);
        } catch (e) {
          console.log(e);
          setCurrentUser({user: null, projects: null});
          if (username == null) {
            var result = await addUserToDatabase(
              user.email,
              user.uid,
              user.displayName
            );
          } else {
            var result = await addUserToDatabase(
              user.email,
              user.uid,
              username
            );
          }
          var nuser = result.user;
          const nprojects = await fetchProjects(nuser.projects);
          const Provider = user.providerData
          setCurrentUser({user: nuser, projects: nprojects, emailVerified: user.emailVerified || Provider[0].providerId === "twitter.com"});
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [username]);

  const value = {
    currentUser,
    login,
    loginWithGoogle,
    loginWithFacebook,
    loginWithTwitter,
    signup,
    loading,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading? <LoadingPage></LoadingPage> : children}
    </AuthContext.Provider>
  );
}
