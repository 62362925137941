import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../../Auth'
import google_button from '../../../assets/images/google.png'
import facebook from '../../../assets/images/facebook.png'
import twitter from '../../../assets/images/twitter.png'

export default function LoginForm() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, signup, loginWithGoogle, loginWithFacebook, loginWithTwitter } = useAuth()
  const [error, setError] = useState("")
  const navigate = useNavigate()

  async function handleLogin(event) {
    event.preventDefault();
    try {
      setError('')
      await login(emailRef.current.value, passwordRef.current.value)
      navigate('/dashboard')
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
        case "auth/user-disable":
        case "auth/user-not-found":
          setError(error.message);
          break;
        case "auth/wrong-password":
          setError(error.message);
          break;
      }
    }
  }

  async function handleGoogleLogin(event) {
    event.preventDefault();
    try {
      setError("");
      await loginWithGoogle();
      navigate("/dashboard");
    } catch (error) {
      setError(error)
    }
  }

  async function handleTwitterLogin(event) {
    event.preventDefault();
    try {
      setError("");
      await loginWithTwitter();
      navigate("/dashboard");
    } catch (error) {
      setError(error)
    }
  }

  async function handleFacebookLogin(event) {
    event.preventDefault();
    try {
      setError("");
      await loginWithFacebook();
      navigate("/dashboard");
    } catch (error) {
      setError(error)
    }
  }
  return (
    <div className='form-container slideFromRightForm'>
      <form className="login-form">
        <input type="email" className="input-login" placeholder="Email" ref={emailRef} required autoFocus></input>
        <input type="password" className="input-login" placeholder="Password" ref={passwordRef} required></input>
        <button type="submit" className="submit-button-login" onClick={handleLogin}>Log in</button>
        {error === "" ? <></> : <div className="form-error">{error}</div>}
        <div className="social-login-container">
          <button type="button" onClick={handleGoogleLogin} className="google-button">
            <img src={google_button} className="google_button_img"></img>
          </button>
          {/* <button type="button" onClick={handleTwitterLogin} className="google-button">
                    <img src={twitter} className="google_button_img"></img>
                </button> */}
        </div>
      </form>

    </div>
  )
}