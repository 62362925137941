import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Auth";

const PrivateRoute = ({ children }) => {
  const {currentUser, loading} = useAuth()
  return currentUser != null && currentUser.user != null ? children: <Navigate to="/login"/>;
}


export default PrivateRoute