import './DashboardHeader.css'
import { useAuth, db } from '../../../Auth'
import logo_dark from '../../../assets/images/tokenstack_logo_text_darkmode.png'
import dashboard_icon from '../../../assets/images/dashboard.png'
import dashboard_menu_icon from '../../../assets/images/dashboardMenu.png'
import profile_icon from '../../../assets/images/profile.png'
import doc_icon from '../../../assets/images/docs.png'
import logout_icon from '../../../assets/images/logout.png'
import logout_menu_icon from '../../../assets/images/logout_menu.png'
import bar from '../../../assets/images/bar.png'
import dashboardExpandArrow from '../../../assets/images/dashboardexpandarrow.png'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

export default function DashboardHeader() {

    const { currentUser, logout, loading } = useAuth()
    const [isExpanded, setIsExpanded] = useState(false)
    const navigate = useNavigate()

    async function handleLogout(e) {
        e.preventDefault()
        try {
            navigate('/login')
            await logout()
        } catch {
            navigate('/dashboard')
        }
    }

    function expand(e) {
        e.preventDefault()
        setIsExpanded(!isExpanded)
    }

    return (
        <div className='DashboardHeader'>
            <div className="DashboardHeaderContent">
                <img src={logo_dark} className="sidebar-logo"></img>
                <div className="TabContainer">
                    <div className='tab-div'>
                        <img src={doc_icon} className="tab-icon"></img>
                        <a href="https://docs.tokenstack.dev/"><button className="tab">Documentation</button></a>
                    </div>
                    <img src={bar}/>
                    <div className='tab-div'>
                        <img src={dashboard_icon} className="tab-icon"></img>
                        <a href='/dashboard'><button className="tab">Dashboard</button></a>
                    </div>
                </div>
                <div className="ProfileSection">
                    <a href='/profile'><button className='tab'>{currentUser.user.username}</button></a>
                    <button onClick={handleLogout}><img src={logout_icon} className="logout-icon"></img></button>
                </div>
                <img src={dashboardExpandArrow} className={isExpanded? "dropwdownArrowButton expandedArrow" : "dropwdownArrowButton"} onClick={expand}></img>
                
            </div>
            <div className="dropdownContent">
                <div className={isExpanded? "dashboardMenuItem first" : 'dashboardMenuItem'}>
                    <img src={doc_icon} className="tab-icon"></img>
                    <a href="https://docs.tokenstack.dev/"><button className="tab">Documentation</button></a>
                </div>
                <div className={isExpanded? "dashboardMenuItem second" : 'dashboardMenuItem'}>
                    <img src={dashboard_menu_icon} className="tab-icon"></img>
                    <a href='/dashboard'><button className="tab">Dashboard</button></a>
                </div>
                <div className={isExpanded? "dashboardMenuItem third" : 'dashboardMenuItem'}>
                    <img src={profile_icon} className="tab-icon"></img>
                    <a href='/profile'><button className='tab'>Profile</button></a>
                </div>
                <div className={isExpanded? "dashboardMenuItem fourth" : 'dashboardMenuItem'}>
                    <button onClick={handleLogout}><img src={logout_menu_icon} className="logout-icon"></img></button>
                </div>
            </div>
        </div>
    )
}