import React from "react";
import greyRibbon from '../../../assets/images/greyRibbon.png'
import seamless from '../../../assets/images/seamless.png'
import ribboncard1 from '../../../assets/images/ribboncard1.png'
import ribboncard2 from '../../../assets/images/NFTLibrary.png'
import './Ribbon.css'

function Ribbon() {
    return (
        <div className="ribbon">
            <div className="ribbon-content">
                <h1 className="keyServices"><b>Key Services</b> We Provide</h1>
                <div className="servicesContainer">
                    <div className="servicesCard">
                        <img src={ribboncard1} className="servicesCardImage" />
                        <div className="servicesCard-heading">Mint an NFT</div>
                        <div className="servicesCard-body">Tokenize your digital assets in a manner that gives you full creative control</div>
                    </div>
                    <div className="servicesCard">
                        <img src={ribboncard2} className="servicesCardImage" />
                        <div className="servicesCard-heading">Visualize NFTs</div>
                        <div className="servicesCard-body">Make real-time changes to your NFT with groundbreaking clarity</div>
                    </div>
                    <div className="servicesCard-last-card">
                        <div className="servicesCard-last-text">See how we can help you.</div>
                        <a href="https://docs.tokenstack.dev/"><p className="getstarted-serviceCard">Get Started</p></a>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Ribbon;