import "./HomePage.css";
import Header from "../UI/Header/Header";
import Landing from "./LandingJumbotron/LandingJumbotron";
import Ribbon from "./Ribbon/Ribbon";
import Impact from "./Impact/Impact";
import Clients from "./Clients/Clients";
import Footer from "../UI/Footer/Footer";
import {animateElement} from "../Animations/FadeIn";
import {useEffect} from "react";
function HomePage() {
  useEffect(() => {
    const Landing = document.querySelector(".Landing");
    const ribbon = document.querySelector(".ribbon-content");
    const impact = document.querySelector(".impact-content-container");
    const clients = document.querySelector(".clients");
    animateElement([Landing, ribbon, impact, clients]);

    const listener = () => {
      animateElement([Landing, ribbon, impact, clients]);
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);

  return (
    <div className="LandingPage">
      <Header />
      <Landing />
      <Ribbon />
      <Impact />
      <Clients />
      <Footer />
    </div>
  );
}

export default HomePage;