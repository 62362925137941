import { useParams } from 'react-router-dom'
import DashboardHeader from '../../DashboardHeader/DashboardHeader'
import './Project.css'
import { db } from '../../../../Auth'
import { useEffect, useState } from 'react'
import LoadingPage from '../../../Loading/LoadingPage'
import Footer from '../../../UI/Footer/Footer'
import { useAuth } from '../../../../Auth'
import NoAcess from './NoAcess/NoAcess'
import { animateElement } from '../../../Animations/FadeIn'
import NFTTokenInfoTable from './NFTTokenInfoTable/NFTTokenInfoTable'
import NFTLibrary from './NFTLibrary/NFTLibrary'

export default function Project() {

    const [project, setProject] = useState(null)
    const [owner, setOwner] = useState(null)
    const { currentUser } = useAuth()

    const params = useParams()

    useEffect(async () => {
        const snapshot = await db.collection("projects").doc(params.projectID).get()
        setProject(snapshot.data())
        const Project = document.querySelector(".Project");
        animateElement([Project]);
    
        const listener = () => {
          animateElement([Project]);
        };
        window.addEventListener("scroll", listener);
        return () => window.removeEventListener("scroll", listener);
        
    }, [])

    useEffect(async () => {
        try {
            const userSnapshot = await db.collection('users').doc(project.owner).get()
            setOwner(userSnapshot.data().username)
        } catch {
            setOwner('')
        }
    }, [project])

    if (project == null) {
        return <LoadingPage/>
    } else if (!project.users.includes(currentUser.user.id)) {
        return <NoAcess></NoAcess>
    } else {
        return (
            <div>
                <DashboardHeader></DashboardHeader>
                <div className='Project'>
                    <div className='ProjectName'>{project.projectName}</div>
                    <div className='info-grid'>
                        <div className='CallsContainer info-container'>
                            <div className='info-label'>Total Calls</div>
                            <div className='info'><span className='gradient-text'>{project.totalApiCalls}</span></div>
                        </div>
                        <div className='CallsContainer info-container'>
                            <div className='info-label'>API Calls</div>
                            <div className='info'><span className='gradient-text'>{project.nftApiCalls}</span></div>
                        </div>
                        <div className='CreditsContainer info-container'>
                            <div className='info-label'>Credits</div>
                            <div className='info'><span className='gradient-text'>{project.credits}</span></div>
                        </div>

                        <div className='ProjectOwnerContainer info-container'>
                            <div className='info-label'>Project ID</div>
                            <div className='info projectID'><span className='gradient-text'>{project.id}</span></div>
                        </div>
                    </div>
                    <div className='info-grid-2'>
                        <NFTTokenInfoTable NFTs={project.nftsMinted}></NFTTokenInfoTable>
                        <NFTLibrary NFTs={project.nftsMinted}></NFTLibrary>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}