import React from "react";
import landingRight from '../../../assets/images/landing-right.png'
import './LandingJumbotron.css'
import axios from "axios";
import tick from '../../../assets/images/tickmark.png'
import { useState, useEffect } from 'react';


function LandingJumbotron() {

    const [email, setEmail] = useState("");
    const [isTick, setIsTick] = useState(false);

    async function addEmailToWaitlist(e) {
        e.preventDefault();
        const waitlist = await axios({
            method: 'post',
            url: " https://us-central1-tokenstack-dev.cloudfunctions.net/app/" + 'waitlist/signUp/',
            data: {
                email: email,
            },
        }).then((response) => console.log(response.data));

        setEmail('');
        setIsTick(true);
    }

    return (
        <div className="Landing">
            <div className="left-landing">
                <h1 className="left-landing-heading"><span className="gradient-text">Democratizing</span> NFT Technology</h1>
                <h2 className="left-landing-body">Tokenstack empowers developers and businesses to integrate NFT capabilities into their applications with easy to use developer tools while supporting the underlying infrastructure.</h2>
                <div className="form-holder">
                    <form className="email-form">
                        <input type="email" id="email" name="email" className="email-input" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} required></input>
                        <button type="submit" value="Get started" className="submit-button" onClick={e => addEmailToWaitlist(e)}>Join Our Newsletter</button>
                    </form>
                    {isTick ? <img src={tick} className="tickmark" /> : <></>}

                </div>
            </div>
            <img src={landingRight} className="landing-right-image" />
        </div>
    );
}

export default LandingJumbotron;